import React from "react";
import "./Login.css";
import { cognitoConfig } from "./config";

const Login = () => {
  const handleSSOClick = (event) => {
    event.preventDefault();
    // Redirect to Cognito Hosted UI
    const url =
      `https://${cognitoConfig.Domain}.auth.${cognitoConfig.Region}.amazoncognito.com/oauth2/authorize?` +
      `response_type=code&client_id=${cognitoConfig.ClientId}&` +
      `redirect_uri=${cognitoConfig.RedirectUriSignIn}&` +
      `identity_provider=${cognitoConfig.IdentityProvider}`;
    console.log("Redirecting to Cognito Hosted UI:", url);
    window.location.href = url;
  };

  return (
    <div className="loginbg_minibox">
      <div id="form-wrapper">
        <form id="idp-login">
          <p className="title">DXS</p>
          <p className="title2">TinyManager</p>
          <div className="sso-btn">
            <button className="loginsso" type="button" onClick={handleSSOClick}>
              Login with KONE account
            </button>
          </div>
          <p className="versionstr">v1.0</p>
        </form>
      </div>
    </div>
  );
};

export default Login;
