import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { cognitoConfig } from "./config";

const LoginCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      const handleAuthResponse = async () => {
        try {
          const tokenUrl = `https://${cognitoConfig.Domain}.auth.${cognitoConfig.Region}.amazoncognito.com/oauth2/token`;
          const data = new URLSearchParams({
            grant_type: "authorization_code",
            client_id: cognitoConfig.ClientId,
            redirect_uri: cognitoConfig.RedirectUriSignIn,
            code,
          });

          const response = await axios.post(tokenUrl, data, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
          });

          const { id_token, access_token } = response.data;

          // Store tokens in sessionStorage
          sessionStorage.setItem("idToken", id_token);
          sessionStorage.setItem("accessToken", access_token);

          console.log("Authentication successful. Redirecting to /home...");
          navigate("/home");
        } catch (error) {
          console.error("Error during token exchange:", error);
          navigate("/login"); // Redirect back to login on failure
        }
      };

      handleAuthResponse();
    } else {
      console.warn("No authorization code found. Redirecting to /login...");
      navigate("/login");
    }
  }, [navigate]);

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <p>Processing your login... Please wait.</p>
    </div>
  );
};

export default LoginCallback;
