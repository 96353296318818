import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./LoginPage/Login";
import LoginCallback from "./LoginPage/LoginCallback";
import Home from "./HomePage/Home";
import NoPage from "./Components/NoPage";
import ProtectedRoute from "./Components/ProtectedRoute";

export default function MainApp() {
  return (
    <div className="redirect">
      <BrowserRouter>
        <Routes>
          {/* Redirect root path to /login */}
          <Route path="/" element={<Navigate to="/login" replace />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logincallback" element={<LoginCallback />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/home" element={<Home />} />
          </Route>
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
