import React from "react";
import { Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const idToken = sessionStorage.getItem("idToken"); // Check if the user is authenticated

  if (!idToken) {
    return (
      <div
        style={{
          width: "300px",
          height: "200px",
          textAlign: "center",
          margin: "50px auto",
          position: "relative",
          backgroundColor: "rgb(255, 255, 255, 0.1)", // Semi-transparent background
          border: "1px solid #ccc",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)", // Subtle shadow for better UI
          padding: "20px",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            zIndex: "1",
            backgroundColor: "rgba(255, 255, 255, 0.8)", // Ensure background is semi-transparent
            borderRadius: "10px",
          }}
        ></div>
        <div style={{ position: "relative", zIndex: "2" }}>
          {" "}
          {/* Content stays above the background */}
          <h2 style={{ color: "#333", marginBottom: "10px" }}>Access Denied</h2>
          <p style={{ color: "#555", marginBottom: "15px" }}>
            Please login first to access this page.
          </p>
          <a
            href="/login"
            style={{
              color: "#007BFF",
              textDecoration: "none",
              fontWeight: "bold",
              border: "1px solid #007BFF",
              padding: "8px 12px",
              borderRadius: "5px",
              display: "inline-block",
              transition: "all 0.3s ease",
            }}
            onMouseOver={(e) => (
              (e.target.style.backgroundColor = "#007BFF"),
              (e.target.style.color = "white")
            )}
            onMouseOut={(e) => (
              (e.target.style.backgroundColor = "transparent"),
              (e.target.style.color = "#007BFF")
            )}
          >
            Go to Login Page
          </a>
        </div>
      </div>
    );
  }

  return <Outlet />; // Render the child component if authenticated
};

export default ProtectedRoute;
